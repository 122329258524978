export enum E_FETCH_STATUS {
  INITIATE = 0,
  FETCHING = 1,
  FETCHED = 2,
  ERROR = 3,
}

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080/blanco_sales_report_be/"
    : "https://report.tranindosejahtera.com/blanco_sales_report_be/";

export const API_URL = {
  signIn: "sign-in",
  checkPassword: "check-password",
  changePassword: "change-password",
  getUserList: "get-all-user",
  getSalesList: "get-sales-list",
  getManagerList: "get-managers",
  getSupervisorList: "get-supervisors",
  getAreaSupervisorList: "get-area-supervisors",
  // User
  getDetailUser: "get-detail-user",
  editUser: "edit-user",
  createNewUser: "create-new-user",
  deactivateUser: "deactivate-user",
  userDataReport: "generate-report-data",
  userDetailDataReport: "generate-detail-report-data",
  userResetPasword: "reset-password-user",
  //
  getShopList: "get-all-shop",
  getShopSelectList: "get-all-shop-select",
  getBranchList: "get-branch-list",
  createNewShop: "create-new-shop",
  getShopDetail: "get-shop-detail",
  editShop: "edit-shop",
  deleteShop: "delete-shop",
  uploadDaerahList: "upload-daerah",
  getProvince: "get-province",
  getKabupaten: "get-kabupaten",
  getKelurahan: "get-kelurahan",
  getKecamatan: "get-kecamatan",
  getZipcode: "get-zipcode",
  uploadProducts: "upload-products",
  getProducts: "get-products",
  createProduct: "create-product",
  getFormProduct: "get-form-product",
  //Products
  getDetailProduct: "get-detail-product",
  editProduct: "edit-product",
  deleteProduct: "delete-product",
  getProductPrice: "get-product-price",
  //

  //Brand
  getBrand: "get-brand",
  createNewBrand: "create-new-brand",
  deleteBrand: "delete-brand",
  getBrandSalesReport: "get-brand-sales-report",
  //

  //Categories
  getCategories: "get-category",
  getCategoriesSelect: "get-category-select",
  createCategory: "create-category",
  deleteCategory: "delete-category",
  //

  //Colour
  getColour: "get-colour",
  getColourSelect: "get-colour-select",
  createColour: "create-colour",
  deleteColour: "delete-colour",
  getSilgranitColour: "get-silgranit-colour",
  //

  getPackage: "get-package",
  createPackage: "create-package",
  deletePackage: "delete-package",

  //Sales Report
  createSalesReport: "create-sales-report",
  getSalesReport: "get-sales-report",
  getSalesReportDetail: "sales-report-detail",
  rejectSalesReport: "reject-sales-report",
  approveSalesReport: "approve-sales-report",

  //Sales Target
  createSalesTarget: "create-comission",
  getSalesTarget: "get-comission",
  getSalesTargetDetail: "comission-detail",
  editSalesTarget: "edit-comission",
  deleteSalesTarget: "delete-comission",
  getFullSalesTargetUser: "get-sales-target",

  //Comission
  countComission: "count-comission",
  getComissionMapping: "get-comission-mapping",
  createComissionCGSPackage: "create-cgs-comission",
  getComissionCGSPackage: "get-cgs-comission",
  deleteComissionCGSPackage: "delete-cgs-comission",
  updateComissionCGSPackage: "update-cgs-comission",
  getComissionCGSPackageDetail: "get-cgs-comission-detail",

  //Comission Category
  getComissionCategory: "get-comission-category",
  getDetailComissionCategory: "get-detail-comission-category",
  createComissionCategory: "create-comission-category",
  deleteComissionCategory: "delete-comission-category",
  getComissionCategorySelect: "get-comission-category-selection",
  updateComissionCategory: "update-comission-category",

  //Finishing
  getFinishing: "get-finishing",
  createFinishing: "create-finishing",
  deleteFinishing: "delete-finishing",

  //Reject Notification
  getRejectNotification: "get-rejection-notification",
  removeNotification: "remove-notification",
};

export enum E_AUTH_STATUS {
  INIT = 0,
  SUPERADMIN = 1,
  SUPERVISOR = 2,
  SALES = 3,
  AREA_SUPERVISOR = 4,
  MANAGER = 5,
}

export enum NEW_USER_STATUS {
  SUPERVISOR = 2,
  SALES = 3,
  AREA_SUPERVISOR = 4,
  MANAGER = 5,
}

export enum E_GENDER {
  INIT = 0,
  MALE = 1,
  FEMALE = 2,
}

export enum E_DISCOUNT_SELECTION {
  BLANCO_NORMAL = 0,
  BLANCO_PROMO = 1,
  CGS_NORMAL = 2,
  CGS_PROMO = 3,
  HG_NORMAL = 4,
  HG_PROMO = 5,
}
export const UserStatus = [
  "",
  "Super Admin",
  "Supervisor",
  "Sales",
  "Area Supervisor",
  "Manager",
];

export enum DASHBOARD_NAV {
  NEW_SALES_REPORT = 1,
  APPROVED_BY_SUPERVISOR = 2,
}

export enum E_PROMO_TYPE {
  ALL = 0,
  PROMO_TOKO = 1,
  PROMO_ONLINE = 2,
  NON_PROMO = 3,
}

export enum USER_DETAIL_NAV {
  DETAIL = 1,
  REPORT = 2,
}

export enum TEMPLATE_BRAND {
  BLANCO = 1,
  CGS = 2,
  HG = 3,
}

export const MONTH = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MONTH_FULL = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

// export const PROMO_TYPE = [null, "Promo Toko", "Promo Online", "Non Promo"];

export const PROMO_TYPE: Record<number, string> = {
  1: "Promo Toko",
  2: "Promo Online",
  3: "Non Promo",
};

export const PEMISAH = "blanco_1111";
// export const SALES_REPORT_STATUS = {
//   1: "Processing",
//   2: "Approved by Supervisor",
//   3: "Approved by Admin",
//   4: "Rejected by Supervisor",
//   5: "Rejected by Admin",
// };

// case 1:
//       return "Processing";
//     case 2:
//       return "Supervisor Approved";
//     case 3:
//       return "Approved By Admin";
//     case 4:
//       return "Rejected By Supervisor";
//     case 5:
//       return "Rejected by Admin";
//     default:
//       return "hah?";
