import { E_FETCH_STATUS } from "../../constant";
import { UserData } from "../../interfaces/user";
import {
  UserAction,
  UserSetAreaSupervisorList,
  UserSetCurrentPage,
  UserSetList,
  UserSetManagerList,
  UserSetStatusLoading,
  UserSetSupervisorList,
  UserSetTotalPage,
  UserType,
} from "../types/user";

interface UserState {
  users: UserData[];
  areaSupervisorList: UserData[];
  supervisorList: UserData[];
  managerList: UserData[];
  statusLoading: E_FETCH_STATUS;
  currentPage: number;
  totalPage: number;
}

const initialState: UserState = {
  users: [],
  areaSupervisorList: [],
  supervisorList: [],
  managerList: [],
  statusLoading: E_FETCH_STATUS.INITIATE,
  currentPage: 1,
  totalPage: 0,
};

const {
  USER_SET_LIST,
  USER_SET_STATUS_LOADING,
  USER_SET_CURRENT_PAGE,
  USER_SET_TOTAL_PAGE,
  USER_SET_MANAGER_LIST,
  USER_SET_AREA_SUPERVISOR_LIST,
  USER_SET_SUPERVISOR_LIST,
} = UserType;

const user = (
  state: UserState = initialState,
  action: UserAction
): UserState => {
  const { type } = action;

  const setUsers = (): UserState => {
    const { payload: users } = action as UserSetList;
    return {
      ...state,
      users,
    };
  };

  const setUserLoading = (): UserState => {
    const { payload: statusLoading } = action as UserSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setUserCurrentPage = (): UserState => {
    const { payload: currentPage } = action as UserSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setUserTotalPage = (): UserState => {
    const { payload: totalPage } = action as UserSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  const setUserManagerList = (): UserState => {
    const { payload: managers } = action as UserSetManagerList;
    return {
      ...state,
      managerList: managers,
    };
  };

  const setUserAreaSupervisorList = (): UserState => {
    const { payload: areaSupervisors } = action as UserSetAreaSupervisorList;
    return {
      ...state,
      areaSupervisorList: areaSupervisors,
    };
  };

  const setUserSupervisorList = (): UserState => {
    const { payload: areaSupervisors } = action as UserSetSupervisorList;
    return {
      ...state,
      supervisorList: areaSupervisors,
    };
  };

  switch (type) {
    case USER_SET_LIST:
      return setUsers();
    case USER_SET_STATUS_LOADING:
      return setUserLoading();
    case USER_SET_CURRENT_PAGE:
      return setUserCurrentPage();
    case USER_SET_TOTAL_PAGE:
      return setUserTotalPage();
    case USER_SET_MANAGER_LIST:
      return setUserManagerList();
    case USER_SET_AREA_SUPERVISOR_LIST:
      return setUserAreaSupervisorList();
    case USER_SET_SUPERVISOR_LIST:
      return setUserSupervisorList();
    default:
      return state;
  }
};

export default user;
